/* eslint-disable import/no-unassigned-import */
import parse from 'html-react-parser';
import { highlight as prismHighlight, languages } from 'prismjs';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-csharp';
import 'prismjs/components/prism-go';
import 'prismjs/components/prism-ruby';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-java';
// Note, the PHP component relies on markup-templating being defined, so we need to import it beforehand
import 'prismjs/components/prism-markup-templating';
import 'prismjs/components/prism-php';
// Note, Objective-C extends C, so we need to import C first
import 'prismjs/components/prism-c';
import 'prismjs/components/prism-objectivec';
import 'prismjs/components/prism-swift';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-kotlin';
import 'prismjs/components/prism-gradle';
import 'prismjs/components/prism-markdown';
import { ReactNode } from 'react';

export type HighlightLanguage =
  | 'curl'
  | 'bash'
  | 'json'
  | 'ruby'
  | 'python'
  | 'java'
  | 'csharp'
  | 'go'
  | 'php'
  | 'javascript'
  | 'nodejs'
  | 'xml'
  | 'html'
  | 'objectivec'
  | 'swift'
  | 'kotlin'
  | 'gradle'
  | 'markdown';

// Map our language types to Prism's grammar names
const languageMap: Record<HighlightLanguage, string> = {
  curl: 'bash',
  bash: 'bash',
  json: 'json',
  ruby: 'ruby',
  python: 'python',
  java: 'java',
  csharp: 'csharp',
  go: 'go',
  php: 'php',
  javascript: 'javascript',
  nodejs: 'javascript',
  xml: 'markup',
  html: 'markup',
  objectivec: 'objectivec',
  swift: 'swift',
  kotlin: 'kotlin',
  gradle: 'gradle',
  markdown: 'markdown',
};

const highlight = (
  code: string | undefined,
  language: HighlightLanguage
): ReactNode => {
  if (!code) {
    return '';
  }

  const prismLanguage = languageMap[language] || 'plain';
  const grammar = languages[prismLanguage];

  const highlightedCode = grammar
    ? prismHighlight(code, grammar, prismLanguage)
    : code;

  const lineTaggedCode = highlightedCode
    .split('\n')
    .map((code: string) => {
      return `<span data-line-number></span>${code}`;
    })
    .join('\n');

  return parse(lineTaggedCode);
};

export { highlight };
